/* CartItem.css */
.cart-item {
    border-bottom: 1px solid #ccc;
    padding: 15px 0;
    transition: background-color 0.3s ease-in-out;
  }
  
  .cart-item:hover {
    background-color: #f8f8f8;
  }
  
  .product-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  
  .color-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  .delete-icon {
    cursor: pointer;
    color: #e74c3c; /* Red color */
    font-size: 20px;
    transition: color 0.3s ease-in-out;
  }
  
  .delete-icon:hover {
    color: #c0392b; /* Darker red color on hover */
  }
  