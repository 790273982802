.navbar {
  background-color: #001f3f;
  padding: 1rem;
}

.navbar-brand {
  color: white;
  font-size: 1.5rem;
}

.navbar-nav .nav-item .nav-link {
  color: white;
  font-size: 1.2rem;
}

.navbar-icons i {
  font-size: 1.5rem;
}

.icon-space {
  margin: 0 10px;
}

.mobile-menu-button {
  font-size: 1.5rem;
  cursor: pointer;
}

.drawer-header {
  display: flex;
  justify-content: flex-end;
}

.drawer-nav .nav-link {
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.drawer-close-button {
  font-size: 2rem;
  cursor: pointer;
}
.navbar {
  background-color: #001f3f;
  padding: 1rem;
}

.navbar-brand {
  color: white;
  font-size: 1.5rem;
}

.navbar-nav .nav-item .nav-link {
  color: white;
  font-size: 1.2rem;
}

.navbar-icons i {
  font-size: 1.5rem;
}

.icon-space {
  margin: 0 10px;
}

.mobile-menu-button {
  font-size: 1.5rem;
  cursor: pointer;
}

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.drawer-header {
  display: flex;
  justify-content: flex-end;
}

.drawer-close-button {
  font-size: 1.5rem;
  cursor: pointer;
}

.drawer-nav {
  list-style: none;
  padding: 0;
}

.drawer-nav .nav-item {
  margin: 1rem 0;
}

.drawer-icons {
  margin-top: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.icon-space {
  margin-left: 10px;
}

.drawer-close-button {
  font-size: 2rem;
  cursor: pointer;
}
/* Navbar.css */
/* .navbar {
  background-color: #001f3f;
  border-bottom: 1px solid #fff;
}

.container {
  text-decoration: none;
}
.navbar-brand {
  color: white;
  font-size: 24px;
}
.navbar-brand:hover {
  color: white;
}

.navbar-nav {
  justify-content: center;
}

.nav-link {
  color: white;
  font-size: 18px;
}

.navbar-icons {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 24px;
  color: white;
}
.icon-space {
  margin-right: 10px; 
} */
