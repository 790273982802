.header {
    background-color: #f8f9fa;
    padding: 3rem 0;
  }
  
  .header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .header h1 {
    font-size: 3rem;
  }
  
  .header p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .header-image {
    display: flex;
    align-items: center;
  }
  
  .header-image img {
    max-width: 100%;
    border-radius: 10px;
    animation: fadeIn 2s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  