/* Login.css */
.login-container {
  background-color: lightgray;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  margin: auto;
  max-width: 400px;
  text-align: center;
}

.user-icon {
  font-size: 100px;
  color: black;
}
