.custom-arrow-left,
.custom-arrow-right {
  z-index: 1;
  transition: opacity 0.3s ease;
}

.custom-arrow-left:hover,
.custom-arrow-right:hover {
  opacity: 0.7;
}

.slick-dots {
  bottom: 20px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: white;
}

.slick-dots li.slick-active button:before {
  color: white;
}
