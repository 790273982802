/* AboutUsPage.css */

.about-us-page {
    padding: 50px 0;
    background-color: #f5f5f5;
  }
  
  .google-maps {
    position: relative;
    overflow: hidden;
    padding-top: 75%; /* Aspect ratio for the map iframe */
  }
  
  .google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .about-image {
    width: 100%;
    height: auto;
    margin-top: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-page h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  