.slider-container {
  padding: 20px;
}

.slide {
  transition: transform 0.3s ease-in-out;
  margin: 0 10px;
  position: relative;
}
.slick-slide.slick-active.slick-center.slick-current {
  opacity: 1 !important;
  margin: 10px !important;
}
.slick-center .slide {
  transform: scale(1.5);
}

.slick-slide {
  transition: transform 0.3s ease-in-out;
}

.slick-slide img {
  border-radius: 12px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.slide:hover .overlay {
  opacity: 1;
}
