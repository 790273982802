/* YourComponent.css */

.card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    transition: transform 0.2s;
    margin-bottom: 20px;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.card-title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.card-text {
    font-size: 1.5rem;
    color: #555;
}
