.payment-form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .payment-form {
    display: flex;
    flex-direction: column;
  }
  
  .card-details-label {
    display: block;
    margin-bottom: 10px;
  }
  
  .card-element {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
  }
  
  .payment-button {
    background: #0074cc;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
  }
  
  .success-message {
    color: green;
  }
  