.product-table {
    border-collapse: collapse;
    width: 80%; /* Adjust the table width as needed */
    margin: 0 auto; /* Center the table horizontally */
  }
  
  .product-table th, .product-table td {
    border: 1px solid #ddd; /* Add borders to table cells */
    padding: 8px; /* Add padding to table cells for spacing */
    text-align: left;
  }
  
  .description-cell {
    max-width: 200px; /* Adjust the maximum width of the description cell */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  