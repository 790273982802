/* TrustedBySection.css */
/* TrustedBySection.css */

.trusted-section {
    background-color: #f5f5f5;
    padding: 50px 0;
  }
  
  .section-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
    color: #333;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .logo {
    width: 120px;
    height: auto;
    margin: 20px; /* Adjust this margin to control the spacing between logo images */
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }
  
  .logo:hover {
    opacity: 1;
  }
  
.trusted-section {
    background-color: lightgray;
    padding: 50px 0;
  }
  
  .section-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
    color: #333;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .logo {
    width: 120px;
    height: auto;
    margin: 20px;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }
  
  .logo:hover {
    opacity: 1;
  }
  