/* YourComponent.css */

.center-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* 100% of the viewport height */
  }
  
 
  