
/* UserCard.css */

.user-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid  black;
  border-radius: 5px;
  width: 80%;
  margin: 0 auto;
}

.user-info {
  width: 100%;
  padding: 10px;
}

.user-image {
  width: 100%;
  max-width: 300px;
}

.user-logout {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.info-label {
  font-weight: bold;
}
.order-history {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  /* Add other styles as needed */
}

.orders-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Add space between orders */
}

.order {
  flex: 1;
  border: 1px solid  black;
  padding: 10px;
  border-radius: 5px;
  /* Add other styles as needed */
}
