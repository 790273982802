/* Common styles for icons */
.icon {
    font-size: 15px;
    color: gray;
    font-weight: bold;
  }
  
  /* Styles for Order Summary */
  .cart-summary {
    background-color: #f4f4f4;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 300px;
    margin: 20px auto;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .cart-summary h2 {
    font-size: 24px;
    color: #333;
  }
  
  .cart-summary p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  

  